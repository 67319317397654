<template>
<footer class="bg-dark text-center text-white">
  <!-- Grid container -->
 
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2024 Copyright:
    <a class="text-white" href="/dashboard">SecureBlog</a>
  </div>
  <!-- Copyright -->
</footer>
</template>

<script>
export default{

}
</script>
